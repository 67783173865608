<template>
  <div>
    <div v-for="item in visibleItems" :key="item.id" class="rosette-button-container">
      <BrandButton
        v-if="item.isVisible"
        shape="rounded"
        @click="item.action"
        class="dynamic-rosette-button"
      >
        <VueText :isSingleLine="true" color="white-100" sizeLevel="3">{{ item.label }}</VueText>
      </BrandButton>
    </div>
  </div>
</template>

<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSurvey from '@/router/routes/secure/RoutesSurvey.js';
import { Common, Rosettes } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import {
  ROSETTE_ITEMS,
  ROSETTE_SUCCESSIBLE_TYPE,
  GTM_ROSETTE_EVENT_TYPE,
  GTM_ROSETTE_LOCATION,
} from '@/modules/rosettes/constants/rosetteConstants';

export default {
  name: 'DynamicRosetteButton',
  mixins: [gtmUtils],
  components: {
    VueText,
    BrandButton,
  },
  props: {
    rosette: {
      type: Object,
    },
    hasActiveMarathons: {
      type: Boolean,
    },
    hasNpsSurvey: {
      type: Boolean,
    },
  },
  computed: {
    visibleItems() {
      return Object.values(ROSETTE_ITEMS).map(item => ({
        ...item,
        action: this[`handle${item.type}`],
        isVisible: this.isItemVisible(item),
      }));
    },
  },
  methods: {
    isItemVisible(item) {
      const isSuccesible = this.rosette.isSuccessible === ROSETTE_SUCCESSIBLE_TYPE.Succesible;
      const isMatchingType = this.rosette.rosetTypeId === item.id;

      switch (item.type) {
        case 'NpsSurvey':
          return isMatchingType && isSuccesible && this.hasNpsSurvey;
        case 'WeeklyPlayable':
          return isMatchingType && isSuccesible && this.hasActiveMarathons;
        default:
          return isMatchingType && isSuccesible;
      }
    },
    handleRouterPush(link) {
      this.$router.push(link);
    },
    handleNpsSurvey() {
      this.$emit('openNpsSurveyPopup');
    },
    handleVirtualVisit() {
      this.pushDataLayerEvent(GTM_ROSETTE_EVENT_TYPE.ECOMMERCE);
      this.pushDataLayerEvent(GTM_ROSETTE_EVENT_TYPE.SEF_DUKKANINDA, {
        location: GTM_ROSETTE_LOCATION.ROZETLERIM,
      });
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`);
    },
    handleWeeklyPlayable() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.WeeklyPlayable.path}`);
    },
    handleEOrder() {
      this.pushDataLayerEvent(GTM_ROSETTE_EVENT_TYPE.ECOMMERCE);
      this.pushDataLayerEvent(GTM_ROSETTE_EVENT_TYPE.ON_SIPARIS_CLICK, {
        location: GTM_ROSETTE_LOCATION.ROZETLERIM,
      });
      Common.getEcommerceUrl().then(res => {
        const { url } = res.data.Data;
        if (url) window.location = url;
      });
    },
    handleChatbot() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Chatbot.path}`);
    },
    handleContent() {
      Rosettes.getRosetteDetail(ROSETTE_ITEMS.Content.id)
        .then(res => {
          if (res?.data?.Data?.detail?.contentId) {
            const link = {
              name: `${RoutesSecure.FeedDetail.name}`,
              params: { id: res.data.Data.detail.contentId },
            };
            this.handleRouterPush(link);
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setModalConfig', {
            isModalOpened: true,
            modalText: 'İçerik bulunamadı',
            modalType: 'uploadAlert',
            alertType: 'error',
            firstButtonText: 'KAPAT',
          });
        });
    },
    handleRegularSurvey() {
      Rosettes.getRosetteDetail(ROSETTE_ITEMS.RegularSurvey.id)
        .then(res => {
          if (res?.data?.Data?.detail?.surveyId) {
            const link = {
              name: `${RoutesSurvey.TakeSurvey.name}`,
              params: { id: res.data.Data.detail.surveyId },
            };
            this.handleRouterPush(link);
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setModalConfig', {
            isModalOpened: true,
            modalText: 'Anket bulunamadı',
            modalType: 'uploadAlert',
            alertType: 'error',
            firstButtonText: 'KAPAT',
          });
        });
    },
    handleUserGuide() {
      const link = {
        path: `${RoutesRoot.Secure.path}/${RoutesSecure.UserManual.path}`,
        query: { isFromRosette: true },
      };
      this.handleRouterPush(link);
    },
    handleEntrepreneurshipAcademy() {
      Rosettes.getRosetteDetail(ROSETTE_ITEMS.EntrepreneurshipAcademy.id).then(res => {
        if (res?.data?.Data) {
          const link = res?.data?.Data?.detail?.url;
          this.handleRouterPush(link);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.rosette-button-container {
  padding: 0 15% !important;
}
.dynamic-rosette-button {
  height: 35px !important;
}
</style>
